<template>
  <span
    class="scroll-bar__item"
    :style="itemStyles"
    @click.stop="handleClick"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'ScrollBarItem',
  data () {
    return {
      index: (this.$parent.$children.length || 1) - 1
    }
  },
  computed: {
    itemStyles () {
      const { reverse, rightGap } = this.$parent

      if (!rightGap) return

      const margin = {}
      if (reverse) {
        margin.marginLeft = `${rightGap}px`
      } else {
        margin.marginRight = `${rightGap}px`
      }
      return {
        ...margin
      }
    }
  },
  methods: {
    handleClick () {
      this.$parent.$emit('input', this.index)
    }
  }
}
</script>
